// is active parent check
import * as XLSX from 'xlsx';
import { CreateExcel } from './excel';

export const isActiveParent = (data = [], path) => {
  if (data?.length !== 0) {
    return data?.some(({ items }) =>
      items?.some(
        (menu) =>
          menu.routePath?.split("/")[1]?.replace(/\/\d+/, "") ===
          path?.split("/")[1]?.replace(/\/\d+/, "")
      )
    );
  }
};

// is active parent childe check
export const isActiveParentChaild = (data = [], path) => {
  if (data?.length !== 0) {
    return data?.some(
      (menu) =>
        menu.routePath.replace(/\/\d+/, "") === path.replace(/\/\d+/, "")
    );
  }
};

// is active link check
export const isActiveLink = (menuPath, routePath) => {
  if (menuPath && routePath) {
    return menuPath.replace(/\/\d+/, "") === routePath.replace(/\/\d+/, "");
  }
};


export const TransformArrayToDicc = (data)=>{
  let transformedObject = data.reduce((acc, obj) => {
    acc[obj.insurance_company_id || obj.id] = obj;
    return acc;
  }, {});
  return transformedObject
}
export const getFormattedDate = (inputDate) =>{
  let date = inputDate;
  
  // Si no se proporciona ninguna fecha, usa la fecha actual
  
  if (!date) {
    date = new Date();
  }else{
    date = new Date(date)
    date.setHours(date.getHours() - 5);
  }
 
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'  };
  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  return formattedDate;
}


export const handleExportToExcel = (data, yearSelected,monthSelected, AgencyName) => {
  let fileName = `Agency_${AgencyName.replace(/ /g, "_")}_${yearSelected}_${monthSelected}.xlsx`;
  let PeriodoComision = `${monthSelected} ${yearSelected}`
  data.map(item=>delete item.id)
  CreateExcel(data, fileName, PeriodoComision, AgencyName)
};
export const SortInsurtechLogins = (InsurtechLogin)=>{
 
  //const UpdateAgentsLogin = InsurtechLogin.filter(e => e.id !== 25);

  const AgentLoginsSort = InsurtechLogin.sort((a, b) => {
    let dateA = new Date(a.created_at);
    let dateB = new Date(b.created_at);
    let numberA = Number(a.fav_click);
    let numberB = Number(b.fav_click);

    let ssoA = Number(a.sso_enable);
    let ssoB = Number(b.sso_enable);

    if (dateB - dateA !== 0) {
      return dateB - dateA;       // Ordena por created_at de forma descendente
    } else if (numberB - numberA !== 0) {
      return numberB - numberA;   // Ordena por fav_click
    } else {
      return a.status.localeCompare(b.status); // Ordena por status
    }
  });
  return AgentLoginsSort
}