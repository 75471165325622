const BASE_URL = `${process.env.NEXT_PUBLIC_LONDONUW_DASHBOARD_API}/api`
const DOMAIN = `${process.env.NEXT_PUBLIC_URL_DOMAIN}`
const MonthDic = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec'
  };
const yearsArray = Array.from({ length: 11 }, (_, i) => new Date().getFullYear() - i);       
module.exports = {
    URL_API:BASE_URL,
    auth_config:{
        "domain": process.env.NEXT_PUBLIC_DOMAIN_AUTH0,
        "clientId": process.env.NEXT_PUBLIC_CLIENT_ID_AUTH0,
        "audience":process.env.NEXT_PUBLIC_AUDIENCE
    },
    URL_INSURANCE_COMPANY:`${BASE_URL}/insurance/company`,
    URL_INSURANCE_COMPANY_CLICK:`${BASE_URL}/insurance/company/first/click`,
    URL_INSURANCE_COMPANY_FAV_CLICK:`${BASE_URL}/insurance/company/click/fav`,
    URL_GENERATOR:`${BASE_URL}/luw/generator/sso`,
    URL_PROFILE:`${BASE_URL}/agents/profile`,
    URL_AGENCY_LOCATION:`${BASE_URL}/agency/office`,
    URL_AGENCY_INFO:`${BASE_URL}/agency/info`,
    URL_COMMISSIONS:`${BASE_URL}/commissions`,
    URL_USERNAME:`${BASE_URL}/agents/sso/username`,
    URL_LOGINS_AGENTS:`${BASE_URL}/agents/login/list`,
    URL_SERVER_ERROR:`${DOMAIN}/server-error`,
    URL_AGENT_ERROR:`${DOMAIN}/access-error`,
    URL_DOMAIN:DOMAIN,
    URL_FORM_AGENT_ERROR:"https://forms.londonuw.com/accountinglondonuwcom/form/SSOAgentIssues/formperma/Qo17AQxxdh_YEhsP2I58ElfE9N0KJ-l30JGMlPfztN0",
    MONTHS:MonthDic,
    YEARS:yearsArray
}