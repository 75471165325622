
import axios from 'axios';
import { URL_GENERATOR, URL_INSURANCE_COMPANY_CLICK,URL_API, URL_INSURANCE_COMPANY, URL_COMMISSIONS, URL_USERNAME, URL_INSURANCE_COMPANY_FAV_CLICK, URL_LOGINS_AGENTS, URL_PROFILE, URL_PROFILE_AGENCY, URL_AGENCY_INFO, URL_AGENCY_LOCATION } from "@/utils/constants";
import * as jose from 'jose'
import { SortInsurtechLogins } from './utils.fn';


const METHODS = {
    GET:'GET',
    POST:'POST',
    PUT:'PUT',
    PATCH:'PATCH'
}
const FN_HTTP_REQUEST = async (URL,METHOD,FN_CALLBACK=()=>{},DATA={},PARAMS={},HEADERS={})=>{
    const token = typeof localStorage != "undefined" ? localStorage.getItem("tokenInfo") : null
    const headers ={
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        ...HEADERS
    }
    const method = METHOD;
    const params = PARAMS;
    const config = {headers,params,method}
   
    switch (METHOD) {
        case METHODS.GET:
            return axios.get(URL, config).then(e=>FN_CALLBACK(e.data));
        case METHODS.POST:
            return axios.post(URL, DATA,{headers}).then(e=>e.data);
        case METHODS.PUT:
            return axios.put(URL, DATA,{headers}).then(e=>e.data);
        case METHODS.PATCH:
            return axios.patch(URL, DATA,{headers}).then(e=>e.data);
        default:
            break;
    }
}

/**GET FUNCTIONS */
export const API_REST_GET_ALIVE = (dto)=>{
    const URL = `${URL_API}`
    return FN_HTTP_REQUEST(URL,METHODS.GET, {},{}, dto);
}
export const API_REST_GET_MENU = (dto,setMenu)=>{
    const URL = `${URL_API}/agents/menu`
    return FN_HTTP_REQUEST(URL,METHODS.GET, setMenu,{}, dto);
}
export const API_REST_INSURANCE_GET_COMPANY = (setCompanies) => {
    const URL = `${URL_API}/insurance/company`
    return FN_HTTP_REQUEST(URL,METHODS.GET,setCompanies)
}
export const API_REST_GET_LOB = (setlob) => {
    const URL = `${URL_API}/insurance/company/lob`
    return FN_HTTP_REQUEST(URL,METHODS.GET,setlob)
}
export const API_REST_GET_CLASS_BY_INSURTECH = (setclass) => {
    const URL = `${URL_API}/insurance/company/class`
    return FN_HTTP_REQUEST(URL,METHODS.GET,setclass)
}
export const API_REST_GET_CLASS = (setclass) => {
    const URL = `${URL_API}/insurance/company/class/all`
    return FN_HTTP_REQUEST(URL,METHODS.GET,setclass)
}
export const API_REST_GET_LOGINS_AGENTS = (id,setInsurtech)=>{
    const URL = URL_LOGINS_AGENTS+'/'+id
    return FN_HTTP_REQUEST(URL,METHODS.GET, (e)=>setInsurtech(SortInsurtechLogins(e?.InsurtechLogin)), {},{});
}
export const API_REST_TRACKING_SEARCH = (id_agent,search)=>{
    const URL = `${URL_API}/agents/search`
    return FN_HTTP_REQUEST(URL,METHODS.POST, (e)=>e, {id_agent,search},{});
}
export const API_REST_TRACKING_MODALOPEN = (dto)=>{
    const URL = `${URL_API}/agents/modalopen`
    return FN_HTTP_REQUEST(URL,METHODS.POST, (e)=>e, dto,{});
}
export const API_REST_GET_LOGIN_REQUESTED = (id_agent,setLoginRequested)=>{
    const URL = `${URL_API}/agents/login/request/`+id_agent
    return FN_HTTP_REQUEST(URL,METHODS.GET,setLoginRequested) 
}
export const API_REST_POST_LOGIN_REQUESTED = (dto)=>{
    const URL = `${URL_API}/agents/login/request`
    return FN_HTTP_REQUEST(URL,METHODS.POST,()=>{},dto)
}

/**POST AND PUT FUNCTIONS */
export const API_REST_POST_REQUEST_MKT = (dto)=>{
    const URL = `${URL_API}/agents/mkt/login/request`
    return FN_HTTP_REQUEST(URL,METHODS.POST,()=>{},dto)
}
export const API_REST_GENERATE_SSO = (dto) => {
    const URL = URL_GENERATOR
    const data = FN_HTTP_REQUEST(URL,METHODS.GET, (a)=>a,{}, dto);
    return (data);
}

export const API_REST_SSO_FIRST_CLICK = (dto)=>{
    const URL = URL_INSURANCE_COMPANY_CLICK
    return FN_HTTP_REQUEST(URL,METHODS.PUT, ()=>{}, dto);
}
export const API_REST_SSO_FAV_CLICK = (dto)=>{
    const URL = URL_INSURANCE_COMPANY_FAV_CLICK
    return FN_HTTP_REQUEST(URL,METHODS.PUT, ()=>{}, dto);
}

export const API_REST_POST_INSURANCE_COMPANY = (dto)=>{
    const URL = URL_INSURANCE_COMPANY
    return FN_HTTP_REQUEST(URL,METHODS.POST, ()=>{}, dto);
}
export const API_REST_GET_COMMISSION_BY_AGENCY = (id,year)=>{
    const URL = URL_COMMISSIONS+'/'+id+'/'+year
    return FN_HTTP_REQUEST(URL,METHODS.GET, (a)=>a, {});
}
export const API_REST_GET_USERNAME = (dto)=>{
    const URL = URL_USERNAME
    return FN_HTTP_REQUEST(URL,METHODS.GET, (a)=>a, {},dto);
}
export const API_REST_UPDATE_USERNAME = (dto)=>{
    const URL = URL_USERNAME
    return FN_HTTP_REQUEST(URL,METHODS.PUT, ()=>{}, dto);
}
export const API_REST_GET_AGENCY = (id, setAgencyInfo) => {
    const URL = `${URL_API}/agency/${id}`
    return FN_HTTP_REQUEST(URL,METHODS.GET, setAgencyInfo)
}
export const API_REST_GET_APPROVAL_HISTORY = (setUsersArray) => {
    const URL = `${URL_API}/admlu/config/profile/approval/history`
    return FN_HTTP_REQUEST(URL,METHODS.GET, setUsersArray)
}
export const API_REST_PUT_APPROVAL_PROFILE = (id, status) => {
    const URL = `${URL_API}/admlu/config/profile/${id}/${status}`
    return FN_HTTP_REQUEST(URL,METHODS.PUT)
}
export const API_REST_GET_LOCATION_AGENCIES_APPROVAL = (setAgenciesArray) => {
    const URL = `${URL_API}/admlu/config/office/approval`
    return FN_HTTP_REQUEST(URL,METHODS.GET, setAgenciesArray)
}
export const API_REST_GET_INFORMATION_AGENCIES_APPROVAL = (setAgenciesArray) => {
    const URL = `${URL_API}/admlu/config/info/approval`
    return FN_HTTP_REQUEST(URL,METHODS.GET, setAgenciesArray)
}
export const API_REST_PUT_APPROVAL_AGENCY_LOCATION = (id, status) => {
    const URL = `${URL_API}/admlu/config/office/${id}/${status}`
    return FN_HTTP_REQUEST(URL,METHODS.PUT)
}
export const API_REST_PUT_APPROVAL_AGENCY_INFO = (id, status) => {
    const URL = `${URL_API}/admlu/config/info/${id}/${status}`
    return FN_HTTP_REQUEST(URL,METHODS.PUT)
}
export const API_REST_PATCH_APPROVAL_AGENT = (id, status) => {
    const URL = `${URL_API}/admlu/config/agents/${id}/${status}`
    return FN_HTTP_REQUEST(URL,METHODS.PATCH)
}
export const API_REST_GET_AGENTS_APPROVAL = (setAgenciesArray) => {
    const URL = `${URL_API}/admlu/config/agents/approval/history`
    return FN_HTTP_REQUEST(URL,METHODS.GET, setAgenciesArray)
}
export async function getToken(getAccessTokenSilently, logout,setToken) {
    if (typeof localStorage == "undefined") return;
    
    var accessToken = localStorage.getItem('tokenInfo');
    var params = {
        ignoreCache: true,
        audience: `${process.env.LONDONUW_DASHBOARD_API}`,
        scope: 'openid profile email offline_access',

    }
    try {
        if (accessToken) {
            const protectedHeader = jose.decodeJwt(accessToken)

            let fechaHora = new Date(protectedHeader.exp * 1000);
            let tiempoEcuador = new Date(fechaHora.getTime() - (5 * 60 * 60 * 1000));


            let tiempoExpiracion = Math.floor(tiempoEcuador.getTime() / 1000);
            let tiempoActual = Math.floor(Date.now() / 1000);

            if (tiempoActual < tiempoExpiracion) {
                console.log("El token aún es válido.");
                // Realizar acciones que requieran un token válido
            } else {
                console.log("El token ha expirado. Generando uno nuevo...");
                accessToken = await getAccessTokenSilently(params);
                localStorage.setItem('tokenInfo', accessToken);
                setToken(accessToken)
            }


        } else {
            accessToken = await getAccessTokenSilently(params);
            localStorage.setItem('tokenInfo', accessToken);
            setToken(accessToken)
        }

   
    } catch (error) {
        console.log(error);
        logout()
        .then(e=> window.location.pathname = "/logout")
        .catch(e=>console.log(e))
        .finally(()=> localStorage.removeItem("tokenInfo"))
    }finally{
        console.clear()
    }


}
export function SetErrorAgents(msj) {
  
    const msjErr = GetErrorAgents();
   
    if(!msj){
        return localStorage.removeItem("msjErr")
    }
    if(!msjErr.includes(msj)){
        msjErr.push(msj)
        return localStorage.setItem("msjErr",JSON.stringify(msjErr))
    }
   
}
export  function GetErrorAgents() {
    try {
        return JSON.parse(localStorage.getItem("msjErr") || [])
    } catch (error) {
        
        return []
    }
    
}

export const API_REST_GET_AGENT_PROFILE = (id,setProfile) => {
    const URL = `${URL_PROFILE}/${id}`;
    const data = FN_HTTP_REQUEST(URL,METHODS.GET, (a)=>setProfile(a),{}, {});
    return (data);
}
export const API_REST_PUT_AGENT_PROFILE = (dto) => {
    const URL = `${URL_PROFILE}/`;
    const data = FN_HTTP_REQUEST(URL,METHODS.PUT, (a)=>(a),dto, {});
    return (data);
}

export const API_REST_GET_AGENCY_LOCATION = (id,setData) => {
    const URL = `${URL_AGENCY_LOCATION}/${id}`;
    const data = FN_HTTP_REQUEST(URL,METHODS.GET, (a)=>setData({id,...a}),{}, {});
    return (data);
}
export const API_REST_PUT_AGENCY_LOCATION = (dto) => {
    const URL = `${URL_AGENCY_LOCATION}/`;
    const data = FN_HTTP_REQUEST(URL,METHODS.PUT, (a)=>(a),dto, {});
    return (data);
}
export const API_REST_GET_AGENCY_INFO = (id,setData) => {
    const URL = `${URL_AGENCY_INFO}/${id}`;
    const data = FN_HTTP_REQUEST(URL,METHODS.GET, (a)=>setData({id,...a}),{}, {});
    return (data);
}
export const API_REST_PUT_AGENCY_INFO = (dto) => {
    const URL = `${URL_AGENCY_INFO}/`;
    const data = FN_HTTP_REQUEST(URL,METHODS.PUT, (a)=>(a),dto, {});
    return (data);
}
export const API_REST_GET_AGENTS_AGENCY = (id,id_agent,setProfile) => {
    const URL = `${URL_API}/agency/agents/${id}/${id_agent}`;
    const data = FN_HTTP_REQUEST(URL,METHODS.GET, (a)=>setProfile(a),{}, {});
    return (data);
}
export const API_REST_UPSERT_AGENTS_AGENCY = (dto) => {
    const URL = `${URL_API}/agency/agents`;
    const data = FN_HTTP_REQUEST(URL,METHODS.POST, (a)=>(a),dto, {});
    return (data);
}
export const API_REST_FILE_UPLOAD = (file,dto) => {
    const URL = `${URL_API}/admlu/config/upload/file`;
    const form = new FormData()
    form.append("content",file)
    form.append("agent_id",dto.agent_id)

    const data = FN_HTTP_REQUEST(URL,METHODS.POST, (a)=>(a),form, {},{'Content-Type':"multipart/form-data"});
    return (data);
}