
import * as XLSX from 'xlsx-js-style';
const COMPANY_NAME = "London Underwriters";
const ADDRESS = "800 Silks Run, Suite 2340 Hallandale, FL 33009"
const PHONE = "(866) 245-5197"
const typeConfig = {
    string: {
        defaultClass: "tableexport-string"
    },
    number: {
        defaultClass: "tableexport-number",
        assert: function (v) {
            return !isNaN(v);
        }
    },
    boolean: {
        defaultClass: "tableexport-boolean",
        assert: function (v) {
            return v.toLowerCase() === "true" || v.toLowerCase() === "false";
        }
    },
    date: {
        defaultClass: "tableexport-date",
        assert: function (v) {
            return !/.*%/.test(v) && !isNaN(Date.parse(v));
        }
    }
}
function dateNum(v, date1904) {
    if (date1904) v += 1462;
    var epoch = Date.parse(v);
    var result = (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
    return Math.floor(result);
}
function createSheet(data, merges, period, agencyName) {
    var ws = {};
    var oWpx = [];
    var offesetFromTop = 10;
    var fWidth = 8;

    var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    var types = typeConfig;
    oWpx[0] = { wpx: 21 * 8 };
    for (var R = 0; R < data.length; ++R) {
        for (var C = 0; C < data[R].length; ++C) {
            //console.log(data[R][C],data[R][C].v)
            if (C < 8 || R == 0 || C == 11) {
                if (oWpx[C] == null || oWpx[C] == undefined) {
                    oWpx.push({ wpx: data[R][C].v.length * fWidth + fWidth });
                } else if (oWpx[C].wpx < data[R][C].v.length * fWidth + fWidth) {
                    oWpx[C].wpx = data[R][C].v.length * fWidth + fWidth;
                }
            } else if (data[R][C].v != " " && data[R][C].v != "") {
                let commas = function (check) { if (check < 1000) { return 0; } return 1 + commas(check / 1000) }
                let neg = data[R][C].v < 0 ? 1 : 0;
                //console.log(neg);
                let form = String(parseFloat(data[R][C].v).toFixed(2)).length + commas(parseInt(data[R][C].v)) + neg + 1;
                //console.log(form)
                if (oWpx[C] == null || oWpx[C] == undefined) {
                    oWpx.push({ wpx: form * fWidth + fWidth });
                } else if (oWpx[C].wpx < form * fWidth + fWidth) {
                    oWpx[C].wpx = form * fWidth + fWidth;
                }
            }
            if (range.s.r > R) range.s.r = R;//check for error maybe
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R + offesetFromTop) range.e.r = R + offesetFromTop;
            if (range.e.c < C) range.e.c = C;
            var cell = data[R][C];
            if (!cell || (!cell.v && cell.v != "0")) continue;
            var cell_ref = XLSX.utils.encode_cell({ c: C, r: R + offesetFromTop });

            if (!cell.t) {
                if (types.number.assert(cell.v)) cell.t = "n";
                else if (types.boolean.assert(cell.v)) cell.t = "b";
                else if (types.date.assert(cell.v)) cell.t = "d";
                else cell.t = "s";
            }
            if (cell.t === "d") {
                cell.t = "n";
                cell.z = XLSX.SSF._table[14];
                cell.v = dateNum(cell.v);
            }
            //oWpx.push({ wpx: 200 });
            ws[cell_ref] = cell;
        }
    }

    //console.log(ws)
    var start = period;
    var name = agencyName;
    var today = new Date();
    today = String(today).substring(0, 34);

    var luInfoStart = { c: 0, r: 0 }
    var agInfoStart = { c: 0, r: 6 }
    var cHeadeStart = { c: 0, r: 4 }
    var coHeaderEnd = { c: 10, r: 4 }

    ws[XLSX.utils.encode_cell({ c: luInfoStart.c, r: luInfoStart.r })] = { v: COMPANY_NAME, t: "s", s: { font: { sz: "12", bold: true, name: "Consolas" } } };
    ws[XLSX.utils.encode_cell({ c: luInfoStart.c, r: luInfoStart.r + 1 })] = { v: ADDRESS, t: "s", s: { font: { sz: "12", name: "Consolas" } } };
    ws[XLSX.utils.encode_cell({ c: luInfoStart.c, r: luInfoStart.r + 2 })] = { v: PHONE, t: "s", s: { font: { sz: "12", name: "Consolas" } } };

    ws[XLSX.utils.encode_cell({ c: cHeadeStart.c, r: cHeadeStart.r })] = { v: "COMMISSION STATEMENT", t: "s", s: { alignment: { horizontal: "center" }, font: { sz: "16", bold: true, underline: true, name: "Consolas" } } };

    ws[XLSX.utils.encode_cell({ c: agInfoStart.c, r: agInfoStart.r })] = { v: "Agency Name: ", t: "s", s: { font: { sz: "12", name: "Consolas" } } };
    ws[XLSX.utils.encode_cell({ c: agInfoStart.c, r: agInfoStart.r + 1 })] = { v: "Commission Period: ", t: "s", s: { font: { sz: "12", name: "Consolas" } } };
    ws[XLSX.utils.encode_cell({ c: agInfoStart.c, r: agInfoStart.r + 2 })] = { v: "Report Creation Date: ", t: "s", s: { font: { sz: "12", name: "Consolas" } } };
    ws[XLSX.utils.encode_cell({ c: agInfoStart.c + 1, r: agInfoStart.r })] = { v: name, t: "s", s: { font: { sz: "12", name: "Consolas" } } };
    ws[XLSX.utils.encode_cell({ c: agInfoStart.c + 1, r: agInfoStart.r + 1 })] = { v: start, t: "s", s: { font: { sz: "12", name: "Consolas" } } };
    ws[XLSX.utils.encode_cell({ c: agInfoStart.c + 1, r: agInfoStart.r + 2 })] = { v: today, t: "s", s: { font: { sz: "12", name: "Consolas" } } };

    merges.push({ s: cHeadeStart, e: coHeaderEnd })

    ws['!cols'] = oWpx;
    ws["!merges"] = merges;
    if (range.s.c < 10000000) ws["!ref"] = XLSX.utils.encode_range(range);
    return ws;
}

function ExportFileExcel(data, name, sheetname, period, agencyName,type) {
    const wb = XLSX.utils.book_new();
    const ws = createSheet(data, [], period, agencyName)
    XLSX.utils.book_append_sheet(wb, ws, sheetname);
    if(type=="Bulk"){
        const wbout = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        return blob;
    }else{
        return XLSX.writeFile(wb,name)
    }
  
}
function CreateExcel(oCommissions, filename, period, agencyName,type) {
  
    var dataURI = [[]];
    if (oCommissions.length > 0) {
        var getReturn = function (val, n) {

            //console.log();//console.log(val)
            var color = nToColor(n);
            return {
                v: val,
                t: "s",
                s: { fill: { fgColor: { rgb: color } }, border: { bottom: { style: "thin", color: { rgb: "FF616162" } } }, font: { name: "Consolas" } },
            };
        };
        var getReturnHeader = function (val, n) {

            var color = nToColor(n);
            return {
                v: val,
                t: "s",
                s: { fill: { fgColor: { rgb: color } }, border: { bottom: { style: "thin", color: { rgb: "FF616162" } } }, font: { bold: true, name: "Consolas" } },
            };
        };
        var nToColor = function (n) {
            switch (n) {
                case 0:
                    return "00FFFFFF"//white
                case 1:
                    return "FFF8F8F9"//gray 
                default:
                    return "FFEDEDED"//darker gray
            }
        }

        var n = 0;
        

        for (var i = 0; i < Object.keys(oCommissions[0] || []).length; i++) { n = 0; dataURI[0].push(getReturnHeader(Object.keys(oCommissions[0] || [])[i], n)); }


        var totAgntCom = 0;
        for (var i = 0; i < oCommissions.length; i++) {
            n++; n = n % 2;
            dataURI.push([]);

            for (var j = 0; j < Object.keys(oCommissions[0]).length; j++) {

                dataURI[i + 1].push(getReturn(oCommissions[i][Object.keys(oCommissions[i])[j]], n));
                if (j == 8 || j == 10) {
                    dataURI[i + 1][j].t = "n";
                    dataURI[i + 1][j].z = "$#,##0.00"
                }
                if (j == 9 || j == 12 || j == 13) { dataURI[i + 1][j].t = "n"; dataURI[i + 1][j].z = "0.00%"; dataURI[i + 1][j].v /= 100 }
            }

            totAgntCom += parseFloat(dataURI[i + 1][10].v);

        }

        dataURI.push([]);
        for (var j = 0; j < Object.keys(oCommissions[0] || []).length; j++) { n = 3; dataURI[i + 1].push(getReturn(" ", n)); }


        dataURI[i + 1][10] = getReturn(totAgntCom.toFixed(2), 3);
        dataURI[i + 1][10].t = "n";
        dataURI[i + 1][10].z = "$#,##0.00";
        dataURI[i + 1][0] = getReturnHeader("TOTAL", 3);


    }
    else {
        dataURI = []
    }
    return ExportFileExcel(dataURI, filename, "Commission Report", period, agencyName,type);




}

export { CreateExcel }